<!--@Time : 2021/11/16 10:53-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row>
			<v-col cols="12" md="12" lg="12">
				<v-data-table
					:headers="headers"
					:items="desserts"
					:loading="loading"
					:page.sync="page"
					:items-per-page.sync="itemsPerPage"
					:server-items-length="totalDesserts"
					class="elevation-0"
				>
					<template v-slot:item.title="{ item }">
						<a @click="openInfoPage(item)">
							{{ item.title }}
						</a>
						<v-badge
							color="primary"
							dot
							v-if="item.state"
							offset-y="-10"
						></v-badge>
					</template>
					<template v-slot:item.action="{ item }">
						<v-icon
							color="primary"
							small
							class="mr-2"
							@click="openEditPage(item)"
							:disabled="$store.getters.groups !== 1"
							>mdi-pencil</v-icon
						>
						<v-icon
							color="error"
							small
							@click="openDeletePage(item)"
							:disabled="$store.getters.groups !== 1"
							>mdi-delete</v-icon
						>
					</template>
				</v-data-table>

				<v-btn
					outlined
					small
					color="primary"
					@click="openNewPage"
					v-if="$store.getters.groups === 1"
				>
					Post an Announcement
				</v-btn>
			</v-col>
		</v-row>

		<v-dialog v-model="infoDialog" max-width="600px">
			<v-card>
				<v-card-title>Announcement</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="9">
							<v-text-field
								v-model="announcement.title"
								label="Title"
								disabled
							></v-text-field>
						</v-col>
						<v-col cols="3">
							<v-text-field
								v-model="announcement.publisher"
								label="By"
								disabled
							></v-text-field>
						</v-col>
					</v-row>
					<p v-html="announcement.text"></p>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="clear"
						>Cancel</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog
			v-model="newDialog"
			max-width="600px"
			eager
			@click:outside="clear"
		>
			<v-card>
				<v-card-title>Post an Announcement</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="9">
							<v-text-field
								v-model="announcement.title"
								label="Title"
							></v-text-field>
						</v-col>
						<v-col cols="3">
							<v-text-field
								v-model="announcement.publisher"
								label="By"
							></v-text-field>
						</v-col>
					</v-row>
					<editor
						v-model="announcement.text"
						api-key="kfhs9glufz4n70ii32f3hwqmjku0wt4cwlijw0l21kk1ql0v"
						:key="tinymce_key"
						:init="{
							resize: false,
							height: '64vh',
							menubar: false,
							plugins: [
								'advlist autolink lists link image charmap print preview anchor',
								'searchreplace visualblocks code fullscreen',
								'insertdatetime media table paste code help wordcount',
							],
							toolbar:
								'undo redo | subscript superscript | charmap | hr | styleselect | fullscreen',
							automatic_uploads: true,
							paste_data_images: true, // 允许直接粘贴图片至组件中,
							save_onsavecallback: this.handleTinySubmit,
						}"
					/>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text small @click="clear"
						>Cancel</v-btn
					>
					<v-btn
						v-if="edit"
						color="primary"
						text
						small
						@click="handleTinySubmit"
						>Update</v-btn
					>
					<v-btn
						v-else
						color="primary"
						text
						small
						@click="handleTinySubmit"
						>Save</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="deleteDialog" max-width="600px">
			<v-card>
				<v-card-title>Confirm delete?</v-card-title>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						small
						@click="deleteDialog = false"
						>No</v-btn
					>
					<v-btn color="primary" text small @click="deleteItemConfirm"
						>Yes</v-btn
					>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Header from "@/components/Header";
import Editor from "@tinymce/tinymce-vue";
import {
  createAnnouncement,
  destroyAnnouncement, infoAnnouncement,
  listAnnouncement, retrieveAnnouncement,
  updateAnnouncementPatch,
} from "@/api/solar/announcement";
import { Message } from "element-ui";
export default {
	name: "Announcements",
	components: { Header, editor: Editor },

	data() {
		return {
			headers: [
				{ text: "#", value: "no", width: 100 },
				{ text: "Title", value: "title" },
				{ text: "Date Posted", value: "date_posted" },
				{ text: "Posted By", value: "posted_by" },
				{ text: "Action", value: "action", width: 200 },
			],
			desserts: [],
			loading: false,
			page: 1,
			itemsPerPage: 10,
			totalDesserts: 0,

			newDialog: false,
			edit: false,
			infoDialog: false,
			deleteDialog: false,

			item: null,
			announcement: {
				text: "",
				title: "",
				publisher: "",
			},
			tinymce_key: 1,
		};
	},
	created() {},
	mounted() {
		this.getDataFromApi();
	},
	activated() {
		this.tinymce_key++;
	},
	watch: {
		page: {
			handler() {
				this.getDataFromApi();
			},
		},
		itemsPerPage: {
			handler() {
				this.getDataFromApi();
			},
		},
	},
	computed: {},
	methods: {
		// 清除
		clear() {
			this.newDialog = false;
			this.infoDialog = false;
			this.edit = false;
			this.announcement = {
				text: "",
				title: "",
				publisher: "",
			};
			this.item = null;
		},

		// 打开新建页面
		openNewPage() {
			this.announcement = {
				text: "",
				title: "",
				publisher: "",
			};
			this.newDialog = true;
		},

		// 上传新通知
		async handleTinySubmit() {
			if (this.edit) {
				await updateAnnouncementPatch(
					this.item.id,
					this.announcement
				).then(() => {
					Message({
						message: "Update successfully",
						type: "success",
					});
				});
				await this.getDataFromApi();
				this.clear();
			} else {
				await createAnnouncement(this.announcement).then(() => {
					Message({
						message: "Post successfully",
						type: "success",
					});
				});
				await this.getDataFromApi();
				this.clear();
			}
		},

		// 打开编辑页面
		openEditPage(item) {
			this.item = item;
			this.announcement = {
				text: item.text,
				title: item.title,
				publisher: item.posted_by,
			};
			this.newDialog = true;
			this.edit = true;
		},

		// 打开详细信息页面
		openInfoPage(item) {
      retrieveAnnouncement(item.id).then((res)=>{
        this.infoDialog = true;
        this.announcement = {
          text: res.text,
          title: res.title,
          publisher: res.posted_by,
        };
        infoAnnouncement().then( (res)=>{
          this.$store.commit("SET_InfoAnnouncement", res['info'])
        })
      })
    },

		// 打开删除页面
		openDeletePage(item) {
			this.deleteDialog = true;
			this.announcement = item;
		},

		// 删除通知
		deleteItemConfirm() {
			destroyAnnouncement(this.announcement.id).then(() => {
				Message({
					message: "Delete successfully",
					type: "success",
				});
				this.deleteDialog = false;
				this.getDataFromApi();
				this.announcement = {
					text: "",
					title: "",
					publisher: "",
				};
			});
		},

		pageSize(pagesize) {
			if (pagesize === -1) {
				return 1000;
			} else return pagesize;
		},

		// 分页请求
		getDataFromApi() {
			this.loading = true;
			listAnnouncement({
				page: this.page, //当前页
				pagesize: this.pageSize(this.itemsPerPage), //每页大小
			}).then((res) => {
				this.totalDesserts = res["count"];
				this.desserts = [];
				res["results"].forEach((item, index) => {
					this.desserts.push({
						no: index + 1,
						id: item["id"],
						title: item["title"],
						text: item["text"],
						date_posted: item["create_time"],
						posted_by: item["publisher"],
					});
				});
				this.loading = false;
			});
		},
	},
};
</script>

<style scoped>
</style>